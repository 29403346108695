import React from 'react';
import { motion } from 'framer-motion';

const MenuHamburger = ({ isOpen = false, width = 50, height = 20, strokeWidth = 2, color = 'black', transition = null, lineProps = null, ...props }) => {
	const variant = isOpen ? 'opened' : 'closed';
	const top = {
		closed: {
			rotate: 0,
			translateY: 0,
		},
		opened: {
			rotate: 35,
			translateY: 0,
			translateX: 0,
		},
	};
	const bottom = {
		closed: {
			rotate: 0,
			translateY: 0,
		},
		opened: {
			rotate: -35,
			translateY: 10,
			translateX: -0,
		},
	};
	lineProps = {
		stroke: color,
		strokeWidth: strokeWidth,
		vectorEffect: 'non-scaling-stroke',
		initial: 'closed',
		animate: variant,
		transition,
		...lineProps,
	};
	const unitHeight = 30;
	const unitWidth = 50; //(unitHeight * width) / height;

	return (
		<motion.svg viewBox={`0 0 ${unitWidth} ${unitHeight}`} overflow='visible' preserveAspectRatio='none' width={width} height={height} {...props}>
			<motion.line x1='0' x2={unitWidth} y1='5' y2='5' variants={top} {...lineProps} transition={{ ...lineProps.transition, delay: 0.2 }} />
			<motion.line x1='0' x2={unitWidth} y1='25' y2='25' variants={bottom} {...lineProps} />
		</motion.svg>
	);
};

export { MenuHamburger };
