import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types'
import { useRouter } from 'next/router';

const LinkFocus = ({ href, children }) => {
	const router = useRouter();

	let selected = '';
	if (router.pathname === href) {
		selected = true;
	}

	return <Link href={href}>{React.cloneElement(children, { selected })}</Link>;
};

LinkFocus.propTypes = {
	href: PropTypes.string,
	children: PropTypes.element
}

export default LinkFocus;