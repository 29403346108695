import React from 'react';
import LinkFocus from '../../components/link/link';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { itemIds } from '../../lib/navigationObject';
// Components
import { SideBarMenu } from '../side-nav-component/side-nav.component';
// Animations
import { menuContainer, menuListAnimation, sideBarAnimation } from '../../styles/base/_animations.styles';

// Styled Components
import { Div, MotionLi, MotionP, MotionUl, SideBarDiv } from './navigation.styles';

const MenuItem = ({ i, label, path }) => {
	return (
		<MotionLi whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} variants={menuListAnimation}>
			<LinkFocus href={path}>
				<MotionP>{label}</MotionP>
			</LinkFocus>
		</MotionLi>
	);
};

const Navigation = () => (
	<MotionUl {...menuContainer}>
		{itemIds.map((i) => (
			<MenuItem alt={`Link to Page ${i.label}`} key={i.id} label={i.label} path={i.path} variants={menuListAnimation} />
		))}
	</MotionUl>
);

const NavigationHamburger = ({ isOpen }) => {
	return (
		<Div alt={'Hamburger menu button'} name={'Hamburger menu button'}>
			<AnimatePresence>
				{isOpen && (
					<SideBarDiv {...sideBarAnimation}>
						<SideBarMenu isOpen={isOpen} />
					</SideBarDiv>
				)}
			</AnimatePresence>
		</Div>
	);
};

NavigationHamburger.propTypes = {
	Component: PropTypes.func,
	i: PropTypes.number,
	label: PropTypes.string,
	path: PropTypes.string,
};

export default NavigationHamburger;
