import React from 'react';
import { motion } from 'framer-motion';
import { colors } from '../../styles/base/_allBase.styles';
import { StyleP, H1, H2 } from '../../styles/base/_base.styles';
import { textAnimation, textAnimationContainer } from '../../styles/base/_animations.styles';

const Wrapper2 = (props) => {
	return (
		<span style={{ padding: '0rem', margin: '0rem', zIndex: 1 }} className='word-wrapper'>
			{props.children}
		</span>
	);
};

React.memo(Wrapper2);

const AnimatedCharacters = (props) => {
	const [text, setText] = React.useState([]);

	const initialProps = React.useMemo(() => props.children.props.children, []);

	const getWordsArray = React.useMemo(() => {
		const words = [];
		const splitWords = initialProps.split(' ');
		for (const [, item] of splitWords.entries()) {
			words.push(item.split(''));
		}
		words.map((word) => {
			return word.push('\u00A0');
		});
		setText(words);
	}, []);

	const Wrapper = ({ children }) => {
		return React.cloneElement(props.children, { ...props.children.props, ...textAnimationContainer }, [...children]);
	};
	return (
		<Wrapper>
			{text.map((word, index) => {
				return (
					<Wrapper2 key={index} style={{ padding: '0rem', margin: '0rem' }}>
						{text[index].flat().map((element, index) => {
							return (
								<span
									style={{
										overflow: 'hidden',
										display: 'inline-block',
									}}
									key={index}
								>
									<motion.span style={{ display: 'inline-block' }} variants={textAnimation}>
										{element}
									</motion.span>
								</span>
							);
						})}
					</Wrapper2>
				);
			})}
		</Wrapper>
	);
};

export default React.memo(AnimatedCharacters);

{
	/* <motion.span key={`sub${index}`} variants={textAnimation}>
									{element}
								</motion.span> */
}
