import boniberry from './boni-berry.svg';
import bookNow from './book-now.svg';
import facebook from './facebook.svg';
import instagram from './instagram.svg';
import sunRise from './sun-rise.svg';
import tikTok from './tiktok.svg';
import twinPeaks from './twin-peaks.svg';
import twitter from './twitter.svg';
import bbLogo from './bblogo.svg';
import bed from './bed.svg';
import ethernet from './ethernet.svg';
import fridge from './fridge.svg';
import occupants from './occupants.svg';
import roomSize from './room-size.svg';
import shower from './shower.svg';
import television from './television.svg';
import wifi from './wifi.svg';
import tripAdvisor from './tripadvisor.svg';
import whatsaAppLogo from './whatsapp.svg';
const socialsArray = [instagram, facebook, tripAdvisor];
const roomAmenityArray = [bed, ethernet, shower, fridge, occupants, roomSize, television, wifi];
export { bbLogo, boniberry, bookNow, facebook, instagram, sunRise, tikTok, twinPeaks, twitter, socialsArray, roomAmenityArray, whatsaAppLogo };
