import React from 'react';
// Components
import { Header } from '../header/header';
import LinkFocus from '../link/link';
import Link from 'next/link';
import { directBookingLink } from '../../lib/companyInfo.js';
// Context
import { useMenu } from '../../context/menu.context';
// Assets
import { structuredNavArray } from '../../lib/navigationObject';
import bbLogo from '../../assets/logo/BB_Acronym-01.png';
import surfer from '../../assets/images/surfer.jpg';
import { socialsArray, bookNow } from '../../assets/svg/allSVG';
// Animations
import { menuListAnimation, motionHover, headerTypography } from '../../styles/base/_animations.styles';
// Styles
import { base, containers, imageStyles, positioning, colors, Img } from '../../styles/base/_allBase.styles';
import {
	GridContainer_Page_Layout,
	MenuStructureContainer,
	GridItem1,
	GridItem2,
	MotionLi,
	MotionUl,
	MotionP,
	FooterContainer,
	SocialContainer,
	PrivacyContainer,
	BookNowContainer,
	NavImage,
} from './side-nav.styles';
const { GridItem_HeaderSection, H1, H2, H3, H4, StyleP, GridItem_Section, GridItem_MainSection, GridItem_FooterSection, CenterDiv } = base;
const { ImgCover, Image, SmallImage } = imageStyles;
const { ImgContainerBox, ImgContainerCarousel } = containers;

const object = {
	header: 'Hospitality',
	text: 'A widespread hotel in the heart of Sannio Beneventano, a microcosm of pleasure, beauty and well-being.',
	image: surfer,
	label: ['Boni Beach', 'Rooms & Suites', 'Guest Services', 'How to React Us'],
};

const MenuItem = ({ i, label = 'Label', path, children }) => {
	return (
		<MotionLi whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} variants={menuListAnimation}>
			<LinkFocus href={path}>{children}</LinkFocus>
		</MotionLi>
	);
};

const SideBarMenu = () => {
	const { toggle } = useMenu();
	return (
		<GridContainer_Page_Layout>
			<GridItem_HeaderSection>
				<Header logo={bbLogo} isMenu={true} position={'sticky'} />
			</GridItem_HeaderSection>
			<GridItem_MainSection>
				{structuredNavArray.map((item) => {
					return (
						<MenuStructureContainer>
							<GridItem1>
								<CenterDiv>
									<H3>{item.header}</H3>
								</CenterDiv>
								<ImgContainerBox>
									<Image src={item.image} />
								</ImgContainerBox>
								<CenterDiv>
									<StyleP>{item.text}</StyleP>
								</CenterDiv>
							</GridItem1>
							<GridItem2>
								<MotionUl>
									{item.label.map((item, i) => {
										return (
											<MenuItem key={i} path={item.path}>
												<H4 onClick={() => toggle()}>{item.header}</H4>
											</MenuItem>
										);
									})}
								</MotionUl>
							</GridItem2>
						</MenuStructureContainer>
					);
				})}
			</GridItem_MainSection>
			<GridItem_FooterSection>
				<FooterContainer>
					<H4>Contact</H4>
					<SocialContainer>
						{socialsArray.map((item, i) => {
							return (
								<Link href='/'>
									<SmallImage style={{ cursor: 'pointer' }} {...motionHover} src={item} />
								</Link>
							);
						})}
					</SocialContainer>
					<PrivacyContainer>
						<Link href='privacy-policy '>
							<StyleP style={{ cursor: 'pointer' }} {...headerTypography}>
								Privacy Policy
							</StyleP>
						</Link>
						<Link href='privacy-policy '>
							<StyleP style={{ cursor: 'pointer' }} {...headerTypography}>
								Cookie Policy
							</StyleP>
						</Link>
						<Link href='terms-&-conditions'>
							<StyleP style={{ cursor: 'pointer' }} {...headerTypography}>
								Hotel Terms and Conditions
							</StyleP>
						</Link>
						{/* <StyleP style={{ cursor: 'pointer' }} {...headerTypography}>
							Credits
						</StyleP> */}
					</PrivacyContainer>
					<a href={directBookingLink}>
						<BookNowContainer {...headerTypography}>
							<MotionP right={'true'}>Book Now</MotionP>
							<NavImage src={bookNow} />
						</BookNowContainer>
					</a>
				</FooterContainer>
			</GridItem_FooterSection>
		</GridContainer_Page_Layout>
	);
};

export { SideBarMenu };
