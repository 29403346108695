import styled, { css, keyframes, createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import * as breakpoints from '../../styles/abstracts/_breakpoints';
// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const Div = styled(motion.div)`
	position: relative;
	${'' /* height: 5rem; */}
	width: 6rem;
	margin: 0rem 0rem;
	padding: 0rem 0rem;
	min-width: 100%;
	z-index: 10;
`;

export const HamburgerNav = styled(motion.nav)`
	justify-items: center;
	align-items: center;
	z-index: 0;
	height: 3rem;
	width: auto;
	cursor: pointer;
	${'' /* z-index: 1000000; */}
	display: none;
	@media ${xxSmallDevices} {
		display: inline-block;
	}
	@media ${xxSmallDevicesLandscape} {
		display: inline-block;
		margin: auto auto;
	}
	@media ${extraSmallDevices} {
		display: inline-block;
		margin: auto auto;
	}
	@media ${extraSmallDevicesLandscape} {
		display: inline-block;
		${'' /* border: 0.2rem solid red; */}
	}
	@media ${mediumDevices} {
		display: inline-block;
	}
	@media ${mediumDevicesLandscape} {
		display: inline-block;
		align-items: end !important;
		justify-items: end !important;
		justify-self: end !important;
	}
	@media ${largeDevices} {
		display: inline-block;
		align-items: end !important;
		justify-items: end !important;
		justify-self: end !important;
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;
export const MotionPath = styled(motion.path)`
	z-index: 1001;
	${
		'' /* height: 5rem;
	width: 5rem; */
	}
`;

export const SideBarDiv = styled(motion.div)`
	position: fixed;
	overflow-y: scroll;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 0rem 0rem;
	width: 100%;
	background: #fff;
	z-index: 10;
	@media ${xxSmallDevices} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const MotionLi = styled(motion.li)`
	align-text: center;
	top: 0;
	left: 0;
	bottom: 0;
	cursor: pointer;
	z-index: 2;
`;

export const MotionP = styled(motion.p)`
	text-decoration-line: ${(props) => (props.selected ? 'underline' : 'none')};
	font-size: 2rem;
	width: 100%;
	cursor: pointer;
	text-align: center;
`;

export const MotionUl = styled(motion.ul)`
	padding: 0rem 0rem;
	z-index: 999;
`;
